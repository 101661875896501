<template>
  <b-card>
    <div class="d-flex">
      <b-form-input
          id="filter-input"
          v-model="filter"
          type="search"
          class="col-md-4 col-xl-2"
          placeholder="Cerca..."
      ></b-form-input>
      <b-button class="mb-3 ml-auto" variant="success" v-b-modal.editModalVehicle
                @click="newItem">
        + Crea Nuovo
      </b-button>
    </div>
    <b-table
        hover
        bordered
        :filter="filter"
        responsive
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        class="contact-table"
    >
      <template #cell(Azioni)="data" width="100" class="text-center">
        <a class="mx-1 cursor-pointer" @click="editItem(data.item)"
        ><feather type="edit-3" class="feather-sm"></feather
        ></a>
        <a
            class="mx-1 cursor-pointer text-danger"
            @click="deleteItem(data.item)"
        ><feather type="trash-2" class="text-danger feather-sm"></feather
        ></a>
      </template>
    </b-table>
    <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        align="center"
    ></b-pagination>
    <!-- ====================================== -->
    <!-- Edit Contact box -->
    <!-- ====================================== -->
    <b-modal
        ref="edit-modal"
        id="editModalVehicle"
        size="xl"
        hide-header
        @ok="save"
        @cancel="close"
        ok-title="Salva"
        cancel-title="Chiudi"
    >
      <h4 class="mb-4">{{formTitle}}</h4>
      <b-row>
        <!-- vettore -->
        <b-col cols="12" lg="6" class="mb-3">
          <h6>Vettore</h6>
          <VectorSelect ref="vectorSelect" @setSelect="(value) => editedItem.vector_id = value" />
        </b-col>
        <!-- service -->
        <b-col cols="12" lg="6" class="mb-3">
          <TypeVehicleSelect ref="serviceSelect" @setSelect="(value) => editedItem.types_of_services_id = value" />
        </b-col>
        <!-- targa -->
        <b-col cols="12" lg="6" class="mb-3">
          <h6>Targa</h6>
          <b-form-input
              v-model="editedItem.vehicle_number"
              placeholder="Inserisci Targa"
              name="name-group"
              size="lg"
          ></b-form-input>
        </b-col>
        <!-- registration_date -->
        <b-col cols="12" lg="6" class="mb-3">
          <h6>Data Immatricolazione</h6>
          <b-form-datepicker
              id="datepicker-full-width"
              v-model="editedItem.registration_date"
              placeholder="Seleziona Data"
              menu-class="w-100"
              calendar-width="100%"
              :max="new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDay() )"
              class="mb-2"
              :hide-header="false"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="it"
              size="lg"
          ></b-form-datepicker>
        </b-col>
        <!-- note -->
        <b-col cols="12" lg="12" class="mb-3">
          <h6>Note</h6>
          <b-form-textarea
              id="textarea-auto-height"
              rows="3"
              max-rows="8"
              v-model="editedItem.note"
          ></b-form-textarea>
        </b-col>
        <!-- active -->
        <b-col cols="12" lg="12" class="mb-3">
          <b-form-checkbox v-model="editedItem.active" name="check-button" switch size="lg">
            Attivo
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>

export default {
  name: "ServiceList",
  components: {
    VectorSelect: () => import("@/views/users/components/VectorsSelect"),
    TypeVehicleSelect: () => import("@/views/type-of-vehicles/components/Select"),
  },
  data: () => ({
    count_checkbox_caregiver: 0,
    selected_tab_wizard: 0,
    checkbox_caregiver: false,
    loadingWizard: false,
    errorMsg: null,
    sortBy: "id",
    fields: [],
    filter: null,
    totalRows: 1,
    currentPage: 1,
    perPage: 5,
    url: null,
    editedIndex: -1,
    editedItem: {
      id: "",
      vector_id: 0,
      types_of_services_id: 0,
      vehicle_number: "",
      registration_date: "",
      note: "",
      active: 1,
    },
    defaultItem: {
      id: "",
      vector_id: 0,
      types_of_services_id: 0,
      vehicle_number: "",
      registration_date: "",
      note: "",
      active: 1,
    },
  }),
  created() {
    this.initialize();
  },
  mounted() {

  },
  methods: {
    async initialize() {
      await this.$store.dispatch("setDataTable", [])
      if (this.user.user_rule === 'vector') {
        this.fields = [
          { key: "id", sortable: true },
          { key: "vehicle_number", sortable: true, label: 'Targa' },
          "Azioni",
        ]
      } else {
        this.fields = [
          { key: "id", sortable: true },
          { key: "vector.authinfo.name", sortable: true, label: 'Vettore' },
          { key: "vehicle_number", sortable: true, label: 'Targa' },
          "Azioni",
        ]
      }
      const res = await this.axios.get('vehicles')
      await this.$store.dispatch("setDataTable", res.data)
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = item;
      this.$refs["edit-modal"].show();
      const that = this
      setTimeout(function () {
        if (that.user.user_rule === 'vector') {
          that.$refs.vectorSelect.value = that.user.user.id
          that.$refs.vectorSelect.disable = true
          that.editedItem.vector_id = that.user.user.id
        } else {
          that.$refs.vectorSelect.value = item.vector_id
        }
        that.$refs.serviceSelect.value = item.types_of_services_id
        that.editedItem.active = item.active === 1
      }, 400)
    },

    newItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      const that = this
      setTimeout(function () {
        if (that.user.user_rule === 'vector') {
          that.$refs.vectorSelect.value = that.user.user.id
          that.$refs.vectorSelect.disable = true
          that.editedItem.vector_id = that.user.user.id
        }
      }, 400)
    },

    async deleteItem(item) {
      const index = this.items.indexOf(item);
      await this.$swal.fire({
        title: 'Elimina Veicolo',
        text: 'Sei sicuro di voler eliminare '+item.description+'?',
        icon: 'warning',
        confirmButtonColor: '#1f2335',
        confirmButtonText: 'Si, Elimina!',
        showCancelButton: true,
        cancelButtonText: 'No!'
      }).then(async result => {
        if (result.value) {
          const res = await this.axios.delete('vehicle/'+item.id)
          if (res.data.status === 'success') {
            this.items.splice(index, 1);
          }

          this.$swal.fire({
            title: 'Eliminazione Veicolo',
            text: res.data.message,
            icon: res.data.status,
            confirmButtonColor: '#1f2335',
          })
        }
      });

      await this.initialize()
    },
    close() {
      this.$refs["edit-modal"].hide();
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    async save() {
      if (this.user.user_rule === 'vector') {
        this.editedItem.vector_id = this.user.user.id
      }
      const res = await this.axios.post('vehicle', this.editedItem)
      this.$swal.fire({
        title: 'Salvataggio Veicolo',
        text: res.data.message,
        icon: res.data.status,
        confirmButtonColor: '#1f2335',
      })
      this.close();
      await this.initialize();
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuovo Veicolo" : "Modifica Veicolo";
    },
    rows() {
      return this.items.length;
    },
    items () {
      return this.$store.state.dataTable
    },
    user() {
      return this.$store.state.authentication;
    }

  },
  watch: {},
};
</script>

<style>
.contact-table td {
  vertical-align: middle;
}
</style>